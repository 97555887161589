export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px text-center",
    tdClass: "text-center",
  },
  {
    key: "productName",
    subKey: "no",
    label: "field.description",
    localization: true,
  },
  {
    key: "reasonCode",
    label: "field.promotionFunction",
  },
  {
    key: "quantity",
    label: "field.quantity",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "unitPrice",
    label: "field.unitPrice",
    type: "currency",
    thClass: "text-center",
    tdClass: "text-center",
  },
  // {
  //   key: "discount",
  //   label: "field.discount",
  //   type: "currency",
  //   thClass: "text-center",
  //   tdClass: "text-center",
  // },
  {
    key: "lineAmount",
    label: "field.total",
    type: "currency",
    thClass: "text-center",
    tdClass: "text-center",
  },
];
